import { ReactNativePostMessagesEnum } from '@webstore-monorepo/shared/enums';

export function createExternalLinkOnClick(url: string) {
  return function onClick() {
    postRNMessage(url);
  };
}
export const postRNMessage = (url: string) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: ReactNativePostMessagesEnum.OPEN_LINK, payload: url }));
    return true;
  }
};
export const handleOpenLinkInRN = (url: string, openInNewTab?: boolean) => {
  if (!postRNMessage(url)) {
    if (openInNewTab) {
      window.open(url, '_blank');
    } else {
      window.open(url, '_self');
    }
  }
};

function handleWebviewLinkClick(event: MouseEvent) {
  if (!window.ReactNativeWebView) return;
  const anchorElement = (event.target as Element).closest('a');
  if (!anchorElement) return;
  const url = anchorElement.href;
  if (url.includes(window.location.origin)) return;
  window.ReactNativeWebView?.postMessage(JSON.stringify({ type: ReactNativePostMessagesEnum.OPEN_LINK, payload: url }));
  event.preventDefault();
}

(() => {
  document.getElementsByTagName('body')[0].addEventListener('click', handleWebviewLinkClick, { capture: true });
})();
