import type { ReactNativeWebViewSubscriberInterface } from './ReactNativeWebViewSubscriber';
import { ReactNativeWebViewSubscriber } from './ReactNativeWebViewSubscriber';

export * from './createExternalLinkOnClick';

/**
 * @todo This file is ran twice by webpack dev/prod. Need to investigate and fix.
 */

declare global {
  interface Window {
    /**
     * Interface to send messages to ReactNativeWebView
     */
    ReactNativeWebView?: {
      /**
       * Pass data as JSON.stringify({type: 'SOME_ACTION_TYPE', payload: {}});
       * @example
       * window.ReactNativeWebView.postMessage(
       *   JSON.stringify({
       *     type: ReactNativePostMessagesEnum.BACK_TO_LOCATIONS
       *   })
       * );
       * @param {string} data
       */
      postMessage(data: string): void;
    };
    /**
     * Interface to handle messages from ReactNativeWebView
     */
    ReactNativeWebViewSubscriber?: ReactNativeWebViewSubscriberInterface;
    /**
     * Add eventListener that handle clicks on anchor elements
     */
    ReactNativeHideBackToLocations?: boolean;
    ReactNativePlatformOS?: 'ios' | 'android' | 'windows' | 'macos' | 'web';
  }
}

window.ReactNativeWebViewSubscriber = ReactNativeWebViewSubscriber;
