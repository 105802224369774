export enum ReactNativePostMessagesEnum {
  ORDER_NOW = 'home/ORDER_NOW',
  LOYALTY_POINTS = 'home/LOYALTY_POINTS',
  REQUEST_GOOGLE_PAY_CARD_NONCE = 'REQUEST_GOOGLE_PAY_CARD_NONCE',
  REQUEST_GOOGLE_PAY_CARD_NONCE_SUCCESS = 'REQUEST_GOOGLE_PAY_CARD_NONCE_SUCCESS',
  BACK_TO_LOCATIONS = 'BACK_TO_LOCATIONS',
  BACK_TO_HOME = 'BACK_TO_HOME',
  OPEN_LINK = 'OPEN_LINK',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  HOME_ORDER_NOW = 'home/ORDER_NOW',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  HOME_LOYALTY_POINTS = 'home/LOYALTY_POINTS',
  REQUEST_APPLE_PAY_CARD_NONCE = 'REQUEST_APPLE_PAY_CARD_NONCE',
  REQUEST_APPLE_PAY_CARD_NONCE_SUCCESS = 'REQUEST_APPLE_PAY_CARD_NONCE_SUCCESS',
  SET_AUTH = 'SET_AUTH',
  SET_USER = 'SET_USER',
  UPDATE_USER = 'UPDATE_USER',
}
